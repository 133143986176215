import React from 'react';

import {
  TopSection,
  VideoTestimonials,
  WorksGallery,
  Intro,
  CallToAction,
} from '../../Containers/OurWork';

import prjvprv1 from "../../Assets/images/tstm1.jpg";
import prjvprv2 from "../../Assets/images/tstm2.jpg";
import prjvprv3 from "../../Assets/images/tstm3.jpg";
import prjvprv4 from "../../Assets/images/tstm4.jpg";
import prjvprv5 from "../../Assets/images/tstm5.jpg";
import prjvprv6 from "../../Assets/images/tstm6.jpg";

import KleinHero from "../../Assets/images/virtual_tours/mtpotest.jpg";
import KleinHero2 from "../../Assets/images/virtual_tours/mtpotestktchn.jpg";

import videoPreview from "../../Assets/videos/Main Kitchen Testemonail preview.mp4";
// import KleinHero from "../../Assets/images/virtual_tours/mtpoktchns.jpg";
// import GrubinAve from "../../Assets/images/virtual_tours/grubin.jpg";
import kitchen_footer from "../../Assets/images/kitchens/home/home-footer-bg.png";
// const recentProjectData = [
//   {
//     "Heading": "45 Buggey Ln (Kitchen)",
//     "HeroBG": KleinHero,
//     "virtualTour": 'https://my.matterport.com/show/?m=2jNBkn5sqjh',
//   },
//   {
//     "Heading": "26 Grubin Ave",
//     "HeroBG": GrubinAve,
//     "virtualTour": 'https://my.matterport.com/show/?m=i4Dmr9tZkCB',
//   },
// ];
const recentProjectData = [
  {
    "Heading": "45 Buggey Ln",
    "HeroBG": KleinHero,
    "virtualTour": "https://my.matterport.com/show/?m=8S53JhBSUKy&brand=0",
  },
  {
    "Heading": "19 EDENBRIDGE DRIVE",
    "HeroBG": KleinHero2,
    "virtualTour": "https://winsold.com/matterport/embed/358972/iXMseo31Sde",
  },
];


export const OurWorkPage = (props) => {
  
  let phone, email, locations;

  const importAll = (r) => {
    return r.keys().map(r);
  }

  const galleryImages = importAll(
    require.context(
      "../../Assets/images/kitchens/our-works/gallery",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  const topSectionProps = {
    text: 'From small compact condo kitchenettes to luxury kitchens, our recently finished projects speak for themselves',   
  }

  // console.log('our work props', props)

  if (props.data) {
    phone = props.data.kitchen_phone;  
    email = props.data.email;
    locations = props.data.location;
  }
  


  const introData = {
    desc: <>
      Get started using our 3D Creator or call <a href='tel:{phone}'>{phone}</a> to get a free quote
      </>,
    className:"kitchesn__works-intro",
    footerImg: kitchen_footer
  }

  return (
    <>
      <TopSection recentProjectData={recentProjectData} {...topSectionProps} />
      <VideoTestimonials items={[
          { videoId: 'z9nRWBHOmwE', previewVideo: videoPreview },
          { videoId: '7F4ZcDON7AU', previewImage: prjvprv1 },
          { videoId: '5ZTVhlbCo5w', previewImage: prjvprv2 },
          { videoId: '1ch6DPAXZYk', previewImage: prjvprv6 },
          { videoId: 'O8boL1uOdCM', previewImage: prjvprv4 },
          { videoId: 'ciBjGrZhi-8', previewImage: prjvprv5 },
      ]} />
      <WorksGallery images={galleryImages} />
      <Intro {...introData} />
      {/* <CallToAction {...CalltoAction} /> */}
    </>
  )
}